/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useContext, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import axios from 'axios';
import Seo from '../../components/Seo/Seo';
import Layout from '../../components/layout';
import Post from '../../components/Post/Post';
import Title from '../../components/Title/Title';
import Button, { ButtonStyle, ButtonType } from '../../components/Button/Button';
import * as styles from './TrackOrder.module.scss';
import { Context } from '../../store/Provider';
import OrderStatusModal from '../../components/Modal/OrderStatusModal/OrderStatusModal';

const messages = defineMessages({
    title: {
        id: 'trackOrder.title',
        defaultMessage: 'Track Your Order 📦',
    },
    subtitle: {
        id: 'trackOrder.subtitle',
        defaultMessage: 'Here you can track your order real-time information from our database. To view order details and delivery status, please enter your order number to access your order details.',
    },
    moreInformationTitle: {
        id: 'trackOrder.moreInformationTitle',
        defaultMessage: 'More information',
    },
    moreInformationPost: {
        id: 'trackOrder.moreInformationPost',
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non. {br}{br} Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin aliquet sapien ut gravida volutpat. Pellentesque sed risus dui. Cras nec massa libero. Proin ut felis enim. Duis elementum tortor pellentesque vehicula sollicitudin. Praesent et consectetur est. Donec suscipit velit aliquet, congue massa vitae, ornare justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce augue risus, rutrum a blandit a, fringilla ac erat. Nullam ac diam ut ipsum hendrerit ultricies vel ac purus. Suspendisse vel magna id nibh placerat fringilla vitae vel neque. Mauris rhoncus finibus erat. Curabitur tincidunt ante odio, eget sollicitudin mi sollicitudin non.',
    },
    track: {
        id: 'trackOrder.track',
        defaultMessage: 'Track',
    },
    error: {
        id: 'trackOrder.inputError',
        defaultMessage: 'Wrong order id',
    },
    fieldEmpty: {
        id: 'trackOrder.fieldEmpty',
        defaultMessage: 'Field cannot be empty',
    },
    metaTitle: {
        id: 'trackOrder.metaTitle',
        defaultMessage: 'Track Your Order | Cheap-Social.com',
    },
    metaDescription: {
        id: 'trackOrder.metaDescription',
        defaultMessage: 'Here you can track your order real-time information from our database. To view order details and delivery status, please enter your order number to access your order details.',
    },
});

const TrackOrder = () => {
    const { dispatch } = useContext(Context);
    const [id, setId] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        if (!id) return setError(messages.fieldEmpty);
        return axios.get(`${process.env.GATSBY_API_URL}/api/order/${id}`).then(({ data }) => {
            const {
                status, price, quantity, serviceName,
            } = data;
            return dispatch({
                type: 'TOGGLE_MODAL',
                payload: (<OrderStatusModal
                    orderId={id}
                    status={status}
                    price={price}
                    service={serviceName}
                    quantity={quantity}
                />),
            });
        }).catch(() => {
            setError(messages.error);
        });
    }, [id]);

    return (
        <Layout>
            <Seo title={messages.metaTitle} description={messages.metaDescription} />
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.col}>
                        <Title text={messages.title} subtitle={messages.subtitle} align="left" className={styles.white} />
                    </div>
                    <div className={styles.col}>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={
                                classNames(
                                    styles.inputGroup, { [styles.errorInput]: error },
                                )
                            }
                            >
                                <div>
                                    <label
                                        htmlFor="trackId"
                                        className={styles.label}
                                    >
                                        <FormattedMessage id="trackOrder.label" defaultMessage="Enter your order number" />
                                    </label>
                                    <input
                                        className={styles.input}
                                        type="text"
                                        placeholder="Example: CAK-ASFNKALWKple"
                                        value={id}
                                        onChange={(e) => {
                                            setError(null);
                                            setId(e.target.value);
                                        }}
                                    />
                                </div>
                                {error && (
                                    <div className={styles.error}>
                                        <FormattedMessage {...error} />
                                    </div>
                                )}
                            </div>
                            <Button
                                type={ButtonType.SUBMIT}
                                style={ButtonStyle.WHITE}
                                text={messages.track}
                                className={styles.btn}
                            />
                        </form>
                    </div>
                </div>
                <Post title={messages.moreInformationTitle}>
                    <FormattedMessage
                        {...messages.moreInformationPost}
                        values={{
                            br: <br />,
                            h1: (text) => <h1>{text}</h1>,
                            h2: (text) => <h2>{text}</h2>,
                            h3: (text) => <h3>{text}</h3>,
                            h4: (text) => <h4>{text}</h4>,
                            h5: (text) => <h5>{text}</h5>,
                            h6: (text) => <h6>{text}</h6>,
                            b: (text) => <b>{text}</b>,
                        }}
                    />
                </Post>
            </div>
        </Layout>
    );
};

export default TrackOrder;
